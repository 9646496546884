import { INVALID_ACCOUNT } from "../../constants/strings";

export const UNABLE_TO_CREATE_PROFILE = "Unable to create profile";
export const UNABLE_TO_UPDATE_PROFILE = "Unable to update profile";
export const PROFILE_NOT_FOUND = "Profile not found";
export const GENERAL_SLID_ERROR =
  "Please ensure entered value is between SLID000001 and SLID999999";
export const DPD_ACCOUNT_LENGTH = 6;
export const DPD_LOCAL_ACCOUNT_LENGTH = 7;

export const PROFILES = "profiles";
export const PROFILES_FIELD = "profiles";
export const DELETE_PREFIX = "deleteItem-";
export const CREATE_ACCOUNT_NUMBER_FIELD = "customerID";
export const PROFILE_CODE_FIELD = "profileCode";
export const PROFILE_NAME_FIELD = "profileName";
export const ACCOUNT_NUMBER_FIELD = "accountNumber";
export const INTERNATIONAL_BRAND_FIELD = "dpdInternationalBrand"; // dpd international business unit

export const DPD_ACCOUNT_NUMBER_FIELD = "dpdDomesticAccountNumber";
export const DPD_DOMESTIC_OUTBOUND_SLID_FIELD = "dpdDomesticOutboundSlid";
export const DPD_OUTBOUND_SLID_FIELD = "dpdDomesticExchangeOutboundSlid";
export const DPD_INBOUND_SLID_FIELD = "dpdDomesticExchangeInboundSlid";
export const DPD_LOCAL_ACCOUNT_NUMBER_FIELD = "interlinkAccountNumber";

export const INTERNATIONAL_DPD_TOGGLE_FIELD = "internationalDpd";
export const INTERNATIONAL_DPD_LOCAL_TOGGLE_FIELD = "internationalDpdLocal";
export const INTERNATIONAL_ACCOUNT_NUMBER_FIELD =
  "dpdInternationalExpressAccountNumber";
export const INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD =
  "dpdInternationalClassicAccountNumber";
export const INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD =
  "dpdInternationalDirectAccountNumber";
export const INTERNATIONAL_EXPRESS_SLID_FIELD = "dpdInternationalExpressSlid";
export const INTERNATIONAL_CLASSIC_SLID_FIELD = "dpdInternationalClassicSlid";
export const INTERNATIONAL_DIRECT_SLID_FIELD = "dpdInternationalDirectSlid";

export const ACCOUNT_INTERNATIONAL_TAB_FIELDS = [
  INTERNATIONAL_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD,
  INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD,
];

export const INTERNATIONAL_DPD_FIELD = "internationalDpd";
export const INTERNATIONAL_DPD_LOCAL_FIELD = "internationalDpdLocal";

export const INV_DETAILS_COMPANY_NAME_FIELD = "invDetailsCompanyName";
export const INV_DETAILS_ADDRESS_1_FIELD = "invDetailsAddress1";
export const INV_DETAILS_ADDRESS_2_FIELD = "invDetailsAddress2";
export const INV_DETAILS_ADDRESS_3_FIELD = "invDetailsAddress3";
export const INV_DETAILS_ADDRESS_4_FIELD = "invDetailsAddress4";
export const INV_DETAILS_POSTCODE_FIELD = "invDetailsPostcode";
export const INV_DEFAULT_CURRENCY_FIELD = "invDefaultCurrency";
export const INV_TELEPHONE_NUMBER_FIELD = "invTelephoneNumber";
export const INV_SHIPPERS_EORI_NUMBER_FIELD = "invShippersEoriNumber";
export const INV_DESTINATION_TAX_ID_NUMBER_FIELD = "invDestinationTaxIdRegNo";
export const INV_FDAREGISTRATION_NUMBER_FIELD = "invFdaRegistrationNumber";
export const INV_SHIPPERS_VAT_NUMBER_FIELD = "invShippersVatNumber";
export const INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD =
  "invDefaultForExtendedLiability";
export const INV_EXPORT_INVOICE_REQUIRED_FIELD = "invExportInvoiceRequired";
export const INV_INVOICE_TYPE_FIELD = "invInvoiceType";
export const INV_PAGE_HEADER = "invPageHeader";

export const INT_INVOICE_FIELDS = [
  INV_TELEPHONE_NUMBER_FIELD,
  INV_DETAILS_COMPANY_NAME_FIELD,
  INV_DETAILS_ADDRESS_1_FIELD,
  INV_DETAILS_ADDRESS_2_FIELD,
  INV_DETAILS_ADDRESS_3_FIELD,
  INV_DETAILS_ADDRESS_4_FIELD,
  INV_DETAILS_POSTCODE_FIELD,
  INV_DEFAULT_CURRENCY_FIELD,
  INV_SHIPPERS_EORI_NUMBER_FIELD,
  INV_DESTINATION_TAX_ID_NUMBER_FIELD,
  INV_FDAREGISTRATION_NUMBER_FIELD,
  INV_SHIPPERS_VAT_NUMBER_FIELD,
  INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD,
  INV_EXPORT_INVOICE_REQUIRED_FIELD,
  INV_INVOICE_TYPE_FIELD,
  INV_PAGE_HEADER,
];

export const ENABLED_AIR_EXPRESS_PARCEL_FIELD = "enableAirExpressParcel";
export const ENABLED_DPD_CLASSIC_PARCEL_FIELD = "enableDpdClassicParcel";
export const ENABLE_DPD_DIRECT_PARCEL_FIELD = "enableDpdDirectParcel";
export const ENABLE_DPD_CLASSIC_FREIGHT_FIELD = "enableDpdClassicFreight";
export const ENABLE_AIR_CLASSIC_PARCEL_FIELD = "enableAirClassicParcel";
export const ENABLE_DPD_SMALL_PARCEL_FIELD = "enableDpdSmallParcel";
export const ENABLE_DPD_CLASSIC_PALLET_FIELD = "enableDpdClassicPallet";
export const ENABLE_DPD_DIRECT_LITE_FIELD = "enableDpdDirectLite";

export const TELEPHONE_FIELD = "telephone";
export const COUNTRY_NAME_FIELD = "countryName";
export const SUBURB_FIELD = "suburb";
export const CITY_FIELD = "city";
export const STREET_FIELD = "street";
export const COMPANY_FIELD = "company";
export const POSTCODE_FIELD = "postcode";
export const COUNTY_FIELD = "county";

export const ADDRESS_FIELDS = [
  TELEPHONE_FIELD,
  COUNTRY_NAME_FIELD,
  SUBURB_FIELD,
  CITY_FIELD,
  STREET_FIELD,
  COMPANY_FIELD,
  COUNTY_FIELD,
];

export const ASYNC_VALIDATE_ERRORS = {
  [PROFILE_CODE_FIELD]:
    "Profile with this Account No and Profile Code already exists",
  [CREATE_ACCOUNT_NUMBER_FIELD]: INVALID_ACCOUNT,
  [DPD_ACCOUNT_NUMBER_FIELD]: INVALID_ACCOUNT,
  [DPD_LOCAL_ACCOUNT_NUMBER_FIELD]: INVALID_ACCOUNT,
  [INTERNATIONAL_ACCOUNT_NUMBER_FIELD]: INVALID_ACCOUNT,
  [INTERNATIONAL_CLASSIC_ACCOUNT_NUMBER_FIELD]: INVALID_ACCOUNT,
  [INTERNATIONAL_DIRECT_ACCOUNT_NUMBER_FIELD]: INVALID_ACCOUNT,
};

export const USE_MY_DPD_ACCOUNT_SETTINGS_FIELD = "useMyDpdAccountSettings";
export const DEFAULT_WEIGHT_FIELD = "defaultWeight";
export const DEFAULT_INFORMATION_FIELD = "defaultInformation";

export const DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD =
  "defaultDpdDomesticOutboundService";
export const DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD =
  "defaultDpdExchangeOutboundService";
export const DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD =
  "defaultDpdExchangeReturnService";
export const DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD =
  "defaultDpdOffshoreOutboundService";
export const DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD =
  "defaultDpdOffshoreExchangeService";
export const DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD =
  "defaultInterlinkDomesticOutboundService";
export const DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD =
  "defaultInterlinkOffshoreOutboundService";
export const DEFAULT_DPD_LOCAL_INTERNATIONAL_OUTBOUND_SERVICE_FIELD =
  "defaultDpdLocalInternationalOutboundService";
export const DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD =
  "defaultDpdInternationalOutboundService";

export const CREATE_NEW_PROFILE = "createNewProfile";

export const DEFAULTS_TAB_FIELDS = [
  DEFAULT_WEIGHT_FIELD,
  DEFAULT_INFORMATION_FIELD,
  DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD,
  DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_LOCAL_INTERNATIONAL_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD,
];

export const SERVICES_FIELDS = [
  DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD,
  DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_LOCAL_INTERNATIONAL_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_INTERNATIONAL_OUTBOUND_SERVICE_FIELD,
];

export const DEFAULTS_COMMON_FIELDS = [DEFAULT_WEIGHT_FIELD];

export const DEFAULTS_DPD_TAB_FIELDS = [
  DEFAULT_DPD_DOMESTIC_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_EXCHANGE_RETURN_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_DPD_OFFSHORE_EXCHANGE_SERVICE_FIELD,
];

export const TOGGLE_PROFILE_FIELDS = [
  ENABLE_AIR_CLASSIC_PARCEL_FIELD,
  ENABLE_DPD_CLASSIC_FREIGHT_FIELD,
  ENABLE_DPD_CLASSIC_PALLET_FIELD,
  ENABLE_DPD_DIRECT_PARCEL_FIELD,
  ENABLE_DPD_SMALL_PARCEL_FIELD,
  ENABLED_AIR_EXPRESS_PARCEL_FIELD,
  ENABLED_DPD_CLASSIC_PARCEL_FIELD,
  ENABLE_DPD_DIRECT_LITE_FIELD,
];

export const DEFAULTS_LOCAL_DPD_TAB_FIELDS = [
  DEFAULT_INTERLINK_OFFSHORE_OUTBOUND_SERVICE_FIELD,
  DEFAULT_INTERLINK_DOMESTIC_OUTBOUND_SERVICE_FIELD,
];

export const SELECT_EACH_TIME_VALUE = "S";
export const CREATE_OWN_INVOICE_VALUE = "C";
export const GENERATE_INVOICE_ONLINE_VALUE = "G";

export const SELECT_EACH_TIME_INV_TYPE_VALUE = "S";
export const COMMERCIAL_VALUE = "C";
export const PROFORMA_VALUE = "P";

export const INVOICE_REQUIRED_AVAILABLE_VALUES = [
  SELECT_EACH_TIME_VALUE,
  GENERATE_INVOICE_ONLINE_VALUE,
  CREATE_OWN_INVOICE_VALUE,
];
export const INVOICE_TYPE_AVAILABLE_VALUES = [
  SELECT_EACH_TIME_INV_TYPE_VALUE,
  COMMERCIAL_VALUE,
  PROFORMA_VALUE,
];
