import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as Phone } from "../../../../assets/icons/phone.svg";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import { PROFILE_ANALYTICS } from "../../../../constants/analytics";
import { EXTENDENT_LIABILITY } from "../../../../constants/common";
import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_LINE_3,
  ADDRESS_LINE_4,
  COMPANY_NAME,
  DEFAULT_CURRENCY,
  DEFAULT_FOR_EXTENDED_LIABILITY,
  DESTINATION_TAX_ID_NUMBER,
  FDA_REGISTRATION_NO,
  POSTCODE,
  SHIPPERS_EORI_NUMBER,
  SHIPPERS_VAT_NUMBER,
  TEL_NO,
} from "../../../../constants/strings";
import {
  INV_DEFAULT_CURRENCY_FIELD,
  INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD,
  INV_DESTINATION_TAX_ID_NUMBER_FIELD,
  INV_DETAILS_ADDRESS_1_FIELD,
  INV_DETAILS_ADDRESS_2_FIELD,
  INV_DETAILS_ADDRESS_3_FIELD,
  INV_DETAILS_ADDRESS_4_FIELD,
  INV_DETAILS_COMPANY_NAME_FIELD,
  INV_DETAILS_POSTCODE_FIELD,
  INV_FDAREGISTRATION_NUMBER_FIELD,
  INV_SHIPPERS_EORI_NUMBER_FIELD,
  INV_SHIPPERS_VAT_NUMBER_FIELD,
  INV_TELEPHONE_NUMBER_FIELD,
} from "../../../../features/profile/profile.constants";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const ProfileIntInvoiceShippersDetails = ({
  onDropdownClick,
  onTextEntry,
  webEntryCurrency,
}) => (
  <Box width="100%">
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_DETAILS_COMPANY_NAME_FIELD}
          label={COMPANY_NAME}
          component={DecoratedFieldWithError}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_DETAILS_COMPANY_NAME_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_DETAILS_ADDRESS_1_FIELD}
          component={DecoratedFieldWithError}
          label={ADDRESS_LINE_1}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_DETAILS_ADDRESS_1_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_DETAILS_ADDRESS_2_FIELD}
          label={ADDRESS_LINE_2}
          component={DecoratedFieldWithError}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_DETAILS_ADDRESS_2_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_DETAILS_ADDRESS_3_FIELD}
          component={DecoratedFieldWithError}
          label={ADDRESS_LINE_3}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_DETAILS_ADDRESS_3_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_DETAILS_ADDRESS_4_FIELD}
          label={ADDRESS_LINE_4}
          component={DecoratedFieldWithError}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_DETAILS_ADDRESS_4_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_DETAILS_POSTCODE_FIELD}
          component={DecoratedFieldWithError}
          label={POSTCODE}
          inputProps={{ maxLength: 8 }}
          onBlur={(e, value) => {
            onTextEntry(INV_DETAILS_POSTCODE_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          component={SelectWithTitle}
          name={INV_DEFAULT_CURRENCY_FIELD}
          label={DEFAULT_CURRENCY}
          onClick={() => {
            onDropdownClick(INV_DEFAULT_CURRENCY_FIELD);
          }}
        >
          {webEntryCurrency.map((currency) => (
            <MenuItem value={currency.currencyCode} key={currency.currencyCode}>
              <Typography>{currency.currencyDescription}</Typography>
            </MenuItem>
          ))}
        </Field>
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_TELEPHONE_NUMBER_FIELD}
          component={DecoratedFieldWithError}
          label={TEL_NO}
          inputProps={{ maxLength: 15 }}
          icon={<Phone />}
          onBlur={(e, value) => {
            onTextEntry(INV_TELEPHONE_NUMBER_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_SHIPPERS_EORI_NUMBER_FIELD}
          component={DecoratedFieldWithError}
          label={SHIPPERS_EORI_NUMBER}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_SHIPPERS_EORI_NUMBER_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_SHIPPERS_VAT_NUMBER_FIELD}
          component={InputWithTitle}
          label={SHIPPERS_VAT_NUMBER}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_SHIPPERS_VAT_NUMBER_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_DESTINATION_TAX_ID_NUMBER_FIELD}
          component={DecoratedFieldWithError}
          label={DESTINATION_TAX_ID_NUMBER}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_DESTINATION_TAX_ID_NUMBER_FIELD, value);
          }}
        />
      </Box>
      <Box width={1 / 2} p={1}>
        <Field
          name={INV_FDAREGISTRATION_NUMBER_FIELD}
          component={InputWithTitle}
          label={FDA_REGISTRATION_NO}
          inputProps={{ maxLength: 50 }}
          onBlur={(e, value) => {
            onTextEntry(INV_FDAREGISTRATION_NUMBER_FIELD, value);
          }}
        />
      </Box>
    </Box>
    <Box display="flex" flexDirection="row">
      <Box width={1 / 2} p={1}>
        <Field
          component={SelectWithTitle}
          name={INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD}
          label={DEFAULT_FOR_EXTENDED_LIABILITY}
          onClick={() => {
            onDropdownClick(INV_DEFAULT_FOR_EXTENDED_LIABILITY_FIELD);
          }}
        >
          <MenuItem value={1}>
            <Typography>{EXTENDENT_LIABILITY.YES}</Typography>
          </MenuItem>
          <MenuItem value={2}>
            <Typography>{EXTENDENT_LIABILITY.NO}</Typography>
          </MenuItem>
        </Field>
      </Box>
    </Box>
  </Box>
);

ProfileIntInvoiceShippersDetails.propTypes = {
  onTextEntry: PropTypes.func,
  onDropdownClick: PropTypes.func,
  webEntryCurrency: PropTypes.array,
};

export default compose(
  withTrackProps(() => ({
    onTextEntry: (field, value) =>
      createLogEntryPayload(PROFILE_ANALYTICS.TEXT_ENTRY, {
        field,
        value,
      }),
    onDropdownClick: (field) =>
      createLogEntryPayload(PROFILE_ANALYTICS.CLICK_DROPDOWN_LIST, {
        field,
      }),
  }))
)(ProfileIntInvoiceShippersDetails);
