import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { getSequences } from "./sequences.selectors.js";
import {
  addSequence,
  checkSequenceInUse,
  getSequenceByName,
} from "./sequences.service";
import { createLoadingAction } from "../loader/loader.actions";

const ACTION_NAMESPACE = "SEQUENCE";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  CREATE_SEQUENCE: createAsyncActionTypes("CREATE_SEQUENCE"),
  GET_SEQUENCES: createAsyncActionTypes("GET_SEQUENCES"),
  GET_SEQUENCES_IN_USE: createAsyncActionTypes("GET_SEQUENCES_IN_USE"),
});

export const createSequences = createAsyncAction(
  () => (dispatch, getState) => {
    const state = getState();
    const sequences = getSequences(state);
    return Promise.allSettled(sequences.map(addSequence));
  },
  ACTIONS.CREATE_SEQUENCE
);

export const getSequencesBySeqNames = createLoadingAction(
  createAsyncAction(
    (sequences, primaryAccountNumber) =>
      getSequenceByName(
        sequences.map((seq) => seq.seqName),
        primaryAccountNumber
      ),
    ACTIONS.GET_SEQUENCES
  )
);

export const checkSequencesBySeqNames = createLoadingAction(
  createAsyncAction(
    (sequences, primaryAccountNumber) =>
      checkSequenceInUse(
        sequences.map((seq) => seq.seqName),
        primaryAccountNumber
      ),
    ACTIONS.GET_SEQUENCES_IN_USE
  )
);
