import { API_ENDPOINTS } from "../../constants/common";
import { webApi } from "../../utils/api";

export const addSequence = (body) =>
  webApi
    .post({
      path: `${API_ENDPOINTS.SEQUENCE}`,
      body,
    })
    .then((res) => res.data);

export const getSequenceByName = (parcelRange, primaryAccountNumber) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.SEQUENCE}`,
      query: { parcelRange, primaryAccountNumber },
    })
    .then((res) => res.data);

export const checkSequenceInUse = (parcelRange, primaryAccountNumber) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.SEQUENCE}/inuse`,
      query: { parcelRange, primaryAccountNumber },
    })
    .then((res) => res.data);
