import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import {
  FOUND_SLIDS,
  FOUND_SLIDS_FIX,
  FOUND_SLIDS_FIX_CONTACT,
  SAVE,
} from "../../../constants/strings";
import { hideModal } from "../../../features/modal/modal.actions";
import {
  ALIGN_BUTTONS,
  WIDTH_TYPE,
} from "../../../features/modal/modal.constants";

import WarningText from "../../WarningText/WarningText";
import BaseModal from "../BaseModal";
import styles from "./SlidsInUseModal.module.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { uniq } from "lodash";

const getSlidsList = (slidsInUse) => {
  return (
    <DialogContentText className={styles.text}>
      <List>
        {Object.keys(slidsInUse).map((seqName) => (
          <ListItem className={styles.listContainer}>
            <ListItemText
              className={styles.listItemSLID}
              primary={`SLID-${seqName}`}
            />
            <ListItemText
              className={styles.listItemAccounts}
              primary={`In use by ${
                slidsInUse[seqName].total
              } profiles by accounts: ${slidsInUse[seqName].accounts?.join(
                ", "
              )} `}
            />
          </ListItem>
        ))}
      </List>
    </DialogContentText>
  );
};

function SlidsInUseModal({ onCancelClick, initialValues, ...otherProps }) {
  const slidsInUse = initialValues.isSlidInUseByAccounts.reduce((acc, item) => {
    const seqName = item.slid;
    if (!acc[seqName]) {
      acc[seqName] = {
        accounts: [item.accountNumber],
        total: 1,
      };

      return acc;
    }

    acc[seqName] = {
      accounts: uniq([...acc[seqName].accounts, item.accountNumber]),
      total: acc[seqName].total + 1,
    };
    return acc;
  }, {});

  return (
    <BaseModal
      title={FOUND_SLIDS}
      confirmButtonText={SAVE}
      onCloseClick={onCancelClick}
      alignButton={ALIGN_BUTTONS.CENTER}
      widthType={WIDTH_TYPE.LARGE}
      {...otherProps}
    >
      <DialogContent className={styles.content}>
        <WarningText text={FOUND_SLIDS_FIX} />
        {getSlidsList(slidsInUse)}
        <WarningText text={FOUND_SLIDS_FIX_CONTACT} />
      </DialogContent>
    </BaseModal>
  );
}

SlidsInUseModal.propTypes = {
  onCancelClick: PropTypes.func,
  onHide: PropTypes.func,
};

const mapDispatchToProps = {
  onHide: hideModal,
};
export default compose(
  connect(null, mapDispatchToProps),
  withTrackProps((props) => ({
    onConfirmClick: () => {
      props.onHide();
      return props.confirmActionId;
    },
    onCancelClick: () => {
      props.onHide();
      return props.cancelActionId;
    },
  }))
)(SlidsInUseModal);
