export const DELAY = 300;
export const SLIDS = "Slids";
export const SLIDS_INUSE = "Slids In Use";
export const CHANGE_MESSAGE = "Change Message";
export const CREATE_MASTER_USER = "Create Master User";
export const CUSTOMER_VALIDATION = "Customer Validation";
export const NOTIFICATION_TYPES = {
  WARNING: "warning",
};

export const ALIGN_BUTTONS = {
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",
};

export const WIDTH_TYPE = {
  EXTRA_SMALL: "extra_small",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  EXTRA_LARGE: "extra-large",
};
